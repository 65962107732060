import { useEffect, useState } from "react";
import { ConvertOperation } from "../@types/Operations/ConvertOperation";
import ValueSelect from "../ValueSelect/ValueSelect";
import { CurrencyActions, useCurrency } from "../../../context/Currency/CurrencyContext";


const OutputSelect:React.FC <ConvertOperation>
= ({state, coin, value,dispatch, setInputValue, setOutputValue, topIcon, topText}) => {

    
    
    const [availableValue, setAvaliableValue] = useState(0);



    const handleMaxButtonClick = () => {

        const maxValue = availableValue;

        setOutputValue(maxValue);

    
    
        

        dispatch({
            type: CurrencyActions.setReceiveValue,
            payload: { receiveValue: maxValue },
        });
    
        


    }


    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        
        
        let outputValue = parseFloat(e.target.value);

        setOutputValue(outputValue);


        ;

        
    };

    const handleCurrency = (currency: string) => {

        dispatch({
            type: CurrencyActions.setReceiveCurrency,
            payload: { receiveCurrency: currency }
        });

    };
 
    

    return (

        <ValueSelect
        index={2}
        coin={coin}
        inputValue={value}
        onChange={handleChange}
        onCurrencyChange={handleCurrency}
        topText={topText}
        topIcon={topIcon}
        handleMaxButtonClick={handleMaxButtonClick}
        isInput={false}
    
    
        />
  

    );

}

export default OutputSelect;