import { useTranslation } from "react-i18next";

interface RegnumCheckBoxProps {
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const RegnumCheckBox = ({ onChange }: RegnumCheckBoxProps) => {
    const { t } = useTranslation();

    return (
        <div className={`flex gap-2 my-3 md:items-center items-start`}>
            <input
                style={{ width: '1em', height: '1em' }}
                className={`checkbox:h-3/4 bg-gray-50 border border-gray-300 text-gray-900
                text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block
                w-full
                p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 
                dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500
                `}
                onChange={onChange} 
                value={"PJ"}
                type="checkbox"
                id="checkboxInputIsPJ"
            />
            <label htmlFor="checkboxInputIsPJ" 
                className={`flex md:items-center
                flex-wrap gap-2 text-gray-400 text-sm md:text-[1em]`}>
                I am not Brazilian.
            </label>
        </div>
    );
}
