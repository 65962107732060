import React, { ReactNode } from "react";
import Heading from "../Heading/Heading";
import CopyToClipBoard from "../../../../../components/Input/CopyToClipBoard/CopyToClipboard";
import LinkModel from "../Link/Link";
import TextModel from "../../../../../components/Text/text";
import MiniContainer from "./MiniContainer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight, faCircleLeft } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { Button } from 'flowbite-react';
import ProgressBar from "../ProgressBar/ProgressBar";
type DepositsConfig = {
    heading: string | ReactNode;
    subHeading: string | ReactNode;
    description: string;
    copyItem: string;
    qrCode: ReactNode;
    activeIndex: number;
}

const DepositsContainer: React.FC<DepositsConfig> = ({ heading, subHeading, description, activeIndex, copyItem, qrCode }) => {
    const navigate = useNavigate();

    const buttonElement = (
        <div className="flex items-center">
            Complete
            <FontAwesomeIcon className="mx-2" icon={faArrowRight} />
        </div>
    );

    return (
        <MiniContainer>
                <div className={`md:px-24 w-full mx-auto`}>
                            <ProgressBar activeIndex={activeIndex} IsSecondVisible={true} />
                        </div>
            <div className="mx-auto w-full">
                <div className="flex lg:flex-row flex-col py-5 pb-10">
                    <div className="flex flex-col mb-6 md:mb-0 justify-around md:me-16">
                    
                        <div className="flex flex-col text-left">
                            <LinkModel linkPath="/">
                                <FontAwesomeIcon className="me-2" icon={faCircleLeft} />
                                Back
                            </LinkModel>
                            <Heading size="text-3xl text-justify mt-6" content={heading} />
                        </div>
                        <TextModel addons="my-5 md:my-10 text-justify" content={subHeading} />
                        <TextModel color="text-gray-400 text-justify" size="text-md" content={description} />
                    </div>
                    <div className="flex flex-col justify-around mx-auto lg:mx-0 mt-4">
                        <div className="flex justify-center border border-gray-400 w-full p-6 rounded-xl">
                            {qrCode}
                        </div>
                        <CopyToClipBoard copyItem={copyItem} />
                    </div>
                </div>
             { /*  <Button
                    className="w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10"
                    onClick={() => navigate('/')}
                >
                    {buttonElement}
                </Button> */}
            </div>
        </MiniContainer>
    );
};

export default DepositsContainer;
