import { ReactNode, useEffect, useState } from "react";
import { Button } from "flowbite-react";
import MiniContainer from "./MiniContainer";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../ProgressBar/ProgressBar";
import LinkModel from "../Link/Link";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleLeft } from "@fortawesome/free-solid-svg-icons";

type ConversionConfig = {
    children: ReactNode;
    location?: string;
    buttonComponent: ReactNode;
    activeIndex: number;
    onClick?: () => void;
    buttonControl?: string;
    isDisabled: boolean
}

const ConversionContainer: React.FC<ConversionConfig> = ({
    children,
    location,
    buttonComponent,
    activeIndex,
    onClick,
    isDisabled,
    buttonControl,
}) => {
    const navigate = useNavigate();
    const [buttonClassname, setButtonClassname] = useState(buttonControl);

    useEffect(() => {
        handleButtonVisibility();
    }, [buttonControl]);

    const handleButtonVisibility = () => {
        setButtonClassname(buttonControl);
    }

    const handleClick = () => {
        if (onClick) {
            onClick();
        }
        if (location) {
            navigate(location);
        }
    }

    return (
        <MiniContainer className={'relative'}>
            <div className={`md:px-24 w-full mx-auto`}>
                <ProgressBar activeIndex={activeIndex} IsSecondVisible={true} />
            </div>
         
            {children}
            <Button
                onClick={handleClick}
                disabled={isDisabled}
                className='w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10'
            >
                {buttonComponent}
            </Button>
        </MiniContainer>
    );
}

export default ConversionContainer;
