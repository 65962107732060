import React from 'react'
import { BrowserRouter, Route, Routes } from "react-router-dom"
import LoginForm from '../pages/Login/Login';
import SignupForm from '../pages/Signup/Signup';
import { ValidateEmail } from '../pages/ValidateEmail/ValidateEmail';
import Home from '../pages/Home/Home';
import ConversionStep2 from '../pages/Home/Conversion/components/Step2/ConversionStep2';
import Deposit from '../pages/Home/Deposit/Deposit';

const AppRoutes = () => {

    return (

        <BrowserRouter>

            <Routes>
                
                <Route path='/login' element = {<LoginForm />}/>
                <Route path='/validate-email' element = {<ValidateEmail />}/>
                <Route path='/' element = {<Home />}/>
                <Route path='/deposit' element = {<Deposit />}/>
                

                
            </Routes>

        </BrowserRouter>
    )
}

export default AppRoutes;