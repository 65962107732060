import { http } from "../ConnectApi/ConnectApi";

export async function getPixToUsdHistoryController(id: string) {
  

  try {
    const response = await http.get(`business/pay-in/pix-to-token/history?id=${id}`, {
      withCredentials: true
    });

    
    return response;
  } catch (error: any) {
    console.error('Error during register:', error.response?.data || error.message);
    throw error;
  }
}
