import { useNavigate } from "react-router-dom";
import {Button} from "flowbite-react";
import Heading from "../Heading/Heading";
import TextModel from "../../../../../components/Text/text";
import MiniContainer from "../Container/MiniContainer";

export type CompleteProps = {


    buttonText: string | undefined;
    text:string | null;
    path: string;
    completeMessage: string;
    image?: string;
    link?: string;


}


const Completed = ({buttonText, path ,completeMessage ,text, image, link} : CompleteProps) => {

    const navigate = useNavigate();

    return (

<MiniContainer>
                 
             <section className="text-center flex flex-col justify-center  min-h-screen">

                <img src={image ?  image : "/completed-icon.svg"} alt="Image of a dolar sign" className="mx-auto h-1/2
                    max-w-[150px] mb-3" />
                <Heading size="text-3xl" content={completeMessage} />
<div className="flex justify-center items-center space-x-2 mt-10">
                <p className="text-md">{text}</p>
                <a className="text-md font-bold" href={link}>click here to see your transaction</a>
                </div>
                <Button className="w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10"  onClick={()=>navigate(path)} >
                {buttonText}
                </Button>

            </section>

            </MiniContainer>
    );

}

export default Completed;