import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button } from 'flowbite-react';
import { useEffect, useRef, useState, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import LogoGreen from "../../components/LogoGreen";
import AuthCode from 'react-auth-code-input';
import { ENDPOINT } from "../../variaveis/variaveis";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";


const inputItems = ["code1", "code2", "code3", "code4", "code5", "code6"];

export const ValidateEmail = () => {
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef<Array<HTMLInputElement | null>>([]);
    const formRef = useRef<HTMLFormElement>(null);
    const navigate = useNavigate();
    const [result, setResult] = useState<string>("");
    const [formData, setFormData] = useState<{ [key: string]: string }>({});
    const [error, setError] = useState("");
    const [email, setEmail] = useState<string>("");

    const handleOnChange = (res: string) => {
        setResult(res);
    };

    useEffect(() => {
        const storedEmail = localStorage.getItem('email');
        if (storedEmail) {
            setEmail(storedEmail);
        } else {
            navigate('/login');
        }
    }, [navigate]);

    function returnToLogin() {
        navigate('/login');
    }

    const handleRegister = async (e: FormEvent) => {
        e.preventDefault();
        setLoading(true);
        setError('');
    
        const requestData = {
            email: email,
            token: result
        };
    
        try {
            const response = await fetch(`${ENDPOINT}/validate`, {
                method: 'PATCH',
                credentials: 'include',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(requestData),
            });
    
            if (response.ok) {
                console.log("CAI AQUI PORRA")
                toast.success('Account created successfully');
                setTimeout(() => {
                    navigate('/login');
                }, 3000);
                return;
            }
    
            // Tenta analisar a resposta como JSON
            const data = await response.json();
    
            if (!response.ok) {
                setError(data.error);
                throw new Error(data.error || 'Error to login');
            }
        } catch (error) {
            if (error instanceof Error) {
                setError(error.message);
            } else {
                setError('Ocorreu um erro desconhecido');
            }
        } finally {
            setLoading(false);
        }
    };

    const isCodeComplete = result.length === inputItems.length;

    return (
        <div className='flex justify-center items-center h-screen max-w-[382px] mx-auto'>
            <form ref={formRef} onSubmit={handleRegister}>
                <div className="mb-10">
                    <LogoGreen />
                </div>
                <Button onClick={returnToLogin} className="mx-auto text-black flex items-center">
                    <div className="flex items-center">
                        <FontAwesomeIcon className="text-black mr-1" icon={faArrowLeft} />
                        <span className="text-black text-xl">Return to Login</span>
                    </div>
                </Button>
                <h1 className="text-black text-2xl font-bold mb-3">Confirm your e-mail</h1>
                <p>Please enter the code we sent to your email. Don't forget to check your spam folder.</p>
                <div className="flex justify-center items-center my-3">
                    <AuthCode 
                        length={6} 
                        containerClassName="flex justify-center gap-2"
                        inputClassName="w-10 h-10 text-center border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                        onChange={handleOnChange} 
                    />
                </div>
                <Button type="submit" disabled={!isCodeComplete || loading} className={`w-full flex justify-center items-center px-5 py-1 bg-[#0A1F27] rounded-lg text-white mt-10 ${!isCodeComplete || loading ? 'opacity-50 cursor-not-allowed' : ''}`}>
                    {loading ? 'Loading...' : 'Confirm code'}
                </Button>
                {error && <div className="text-red-500 mt-2">{error}</div>}
            </form>
            <ToastContainer />
        </div>
    );
};
