import React, { useEffect, useState, useRef } from "react";
import CurrencyFlags from "./CurrencyFlags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { getCurrencyIMG } from "../../../../../components/services/getCurrencyIMG";

type CurrencyConfig = {
  coin: string;
  onCurrencyChange: (currency: string) => void;
  index: number;
  isInput: boolean;
};

const CurrencyDropdown: React.FC<CurrencyConfig> = ({ coin, onCurrencyChange, index, isInput }) => {
  const [selectedCurrency, setSelectedCurrency] = useState(coin);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const currencies = [
    { code: "GLMR", name: "GLMR (Moonbeam)", flag: getCurrencyIMG('GLMR') },
   //  { code: "BRLA", name: "BRLA (Moonbeam)", flag: getCurrencyIMG('BRLA') },
    // { code: "BRL", name: "Brazilian real", flag: getCurrencyIMG('BRL') },
    // { code: "MATIC", name: "Polygon (MATIC)", flag: getCurrencyIMG('MATIC') },
    // { code: "CHZ", name: "Chillz (CHZ)", flag: getCurrencyIMG('CHZ') },
    // { code: "ETH", name: "Ethereum (ETH)", flag: getCurrencyIMG('ETH') },
  ];

  const handleChange = (currency: string) => {
    setDropdownOpen(false);
    setSelectedCurrency(currency);
    onCurrencyChange(currency);
  };

  const handleClick = () => {
    if (!isInput) {
      setDropdownOpen(!dropdownOpen);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef]);

  const selectedCurrencyFlag = isInput
    ? getCurrencyIMG('BRL')
    : currencies.find(({ code }) => code === selectedCurrency)?.flag;

  return (
    <div ref={dropdownRef} className="relative w-full inline-block text-left" data-index={index} data-dropdown={dropdownOpen ? 'open' : 'closed'}>
      <button
        className={`flex w-auto justify-start ${isInput ? 'pr-10' : ''} rounded-md border-transparent shadow-sm px-4 py-4 bg-white font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-indigo-500 text-lg items-center`}
        id="dropdown-button"
        aria-expanded={dropdownOpen ? "true" : "false"}
        aria-haspopup="true"
        onClick={handleClick}
      >
        {selectedCurrencyFlag && (
          <img className="w-5 h-5 mr-2 rounded-full" src={selectedCurrencyFlag} alt={`${selectedCurrency} flag`} />
        )}
        {selectedCurrency}
        {!isInput && <FontAwesomeIcon icon={faChevronDown} className={`ml-2 h-5 w-5 transition-transform transform ${dropdownOpen ? 'rotate-180' : 'rotate-0'}`} />}
      </button>

      {dropdownOpen && <CurrencyFlags currencies={currencies} handleChange={handleChange} />}
    </div>
  );
};

export default CurrencyDropdown;
