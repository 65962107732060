import React, { useEffect, useRef, useState } from "react";
import ContainerService from "../Container/ContainerService";
import ConversionContainer from "../Container/ConversionContainer";
import TextModel from "../../../../../components/Text/text";
import ConversionCard from "../ConversionCard/ConversionCard";
import IconBall from "../../../IconBall/IconBall";
import Loading from "../Loading/Loading";
import Completed, { CompleteProps } from "../Completed/Completed";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightArrowLeft, faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { formatNumberToString } from "../../../../../components/services/formatNumber";
import { getCurrencyIMG } from "../../../../../components/services/getCurrencyIMG";
import { closePixToTokenController } from "../../../../../controller/ClosePixToTokenDealController/ClosePixToTokenDealController";
import { CurrencyActions, useCurrency } from "../../../../../context/Currency/CurrencyContext";
import { useNavigate } from "react-router-dom";
import { fastQuoteController } from "../../../../../controller/FastQuoteController/FastQuoteController";

const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay !== null) {
            const id = setInterval(() => savedCallback.current(), delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

const ConversionStep2: React.FC = () => {
    const { state, dispatch } = useCurrency();
    const [loading, showLoading] = useState(false);
    const [completed, showCompleted] = useState(false);
    const [buttonClassname, setButtonClassname] = useState('opacity-50 pointer-events-none');
    const [baseFee, setBaseFee] = useState(0);
    const [markupFee, setMarkupFee] = useState(0);
    const [quoteId, setQuoteId] = useState<number>(0);
    const [success, onSuccess] = useState(false);
    const [error, onError] = useState(false);
    const [walletAddress, setWalletAddress] = useState('');
    const [inputValue, setInputValue] = useState(0);
    const [outputValue, setOutputValue] = useState(0);
    const [errorMessage, setErrorMessage] = useState<string>('');
    const navigate = useNavigate();
    const [onSuccessMessage, setSuccessMessage] = useState<CompleteProps>({
        buttonText: '',
        completeMessage: '',
        text: '',
        path: '',
        image: '',
    });

    const chains = {
        'BRL': '',
        'GLMR': 'Moonbeam',
        'MATIC': 'Polygon',
        'CHZ': 'Chiliz',
        'ETH': 'Ethereum'
    };

    const fetchQuote = async () => {
        const inputString = (state.sendValue * 100).toFixed(0);
        const inputInt = parseFloat(inputString);
        const data = {
            operation: "pix-to-token",
            amount: inputInt,
            inputCoin: "BRLA",
            outputCoin: state.receiveCurrency,
            chain: chains[state.receiveCurrency]
        };
        try {
            const ApiResponse = await fastQuoteController(data);
            const response = ApiResponse.data;
            const amountToken = response.amountToken; 
            const amountBrl = response.amountBrl;
            
            dispatch({
                type: CurrencyActions.setReceiveValue,
                payload: { receiveValue: amountToken }
            });
    
            dispatch({
                type: CurrencyActions.setSendValue,
                payload: { sendValue: amountBrl }
            });
         
        } catch (error:any) {
            if (error.response?.status === 401) {
                navigate('/login')
            }
        
            console.error("Error in fetchQuote:", error.response?.data || error.message);
            setErrorMessage(error.response?.data?.message ||  error.response?.data?.error || "An error occurred while fetching the quote.");
        } 
    } ;

    useEffect(() => {
        fetchQuote();
    }, [state.sendValue, state.receiveCurrency]);

    useInterval(() => {
        fetchQuote();
    }, 5000);

    async function handleSubmit() {
        setButtonClassname('opacity-50 pointer-events-none');
        showLoading(true);
        const amountCents = (state.sendValue * 100).toFixed(0);
        const amountInt = parseFloat(amountCents);

        try {
            const data = {
                amount: amountInt,
                token: state.receiveCurrency,
                receiverAddress: walletAddress
            };
            const ApiResponse = await closePixToTokenController(data);
            const response = ApiResponse.data;
            const brCode = response.brCode;
            const pixToUsdId = response.id;
            dispatch({
                type: CurrencyActions.setBrCode,
                payload: { brCode: brCode }
            });
            dispatch({
                type: CurrencyActions.setPixToUsdId,
                payload: { pixToUsdId: pixToUsdId }
            });
            navigate('/deposit');
            showLoading(false);

            return response;
        } catch (error: any) {
            if (error.response?.status === 401) {
                navigate('/login');
            }
            console.error("Error in handleSubmit:", error.response?.data || error.message);
            setErrorMessage(error.response?.data?.error || "An error occurred while processing the transaction.");
            showLoading(false);
        }
        showLoading(false);
    }

    function isValidERC20Address(address: string) {
        const re = /^0x[a-fA-F0-9]{40}$/;
        return re.test(address);
    }

    const handleTaxIdChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setWalletAddress(e.target.value);
        if (!isValidERC20Address(e.target.value)) {
            setErrorMessage("Please enter a valid ERC-20 wallet address");
        } else {
            setErrorMessage("");
        }

        if (!e.target.value) {
            setErrorMessage("");
        }
    };

    return (
        <ContainerService path='/' linkText="Dashboard">
            <ConversionContainer
                onClick={handleSubmit}
                isDisabled={!walletAddress || !isValidERC20Address(walletAddress) || loading}
                activeIndex={1}
                buttonControl={buttonClassname}
                buttonComponent={
                    <>
                        Confirm and pay
                    </>
                }
            >
                {!loading && !completed && (
                    <>
                        <TextModel
                            size="text-lg"
                            addons="text-gray-400 mx-auto text-center"
                            content={
                                <>
                                    <FontAwesomeIcon icon={faExclamationCircle} className="me-2" />
                                    {`1 ${state.receiveCurrency} = ${(state.sendValue / state.receiveValue).toFixed(2)}  BRL`}
                                </>
                            }
                        />

                        <ConversionCard currency={state.sendCurrency} value={formatNumberToString(state.sendValue, state.sendCurrency)} />
                        <IconBall absolute={false} icon={faArrowRightArrowLeft} rotation={90} />
                        <ConversionCard currency={state.receiveCurrency} value={parseFloat(String(state.receiveValue)).toFixed(2)} />

                        <div className="flex mt-6 items-center">
                            <input 
                                type="text" 
                                className="form-input w-full py-2 px-3 rounded-md bg-gray-100 border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent pr-10" 
                                placeholder="Wallet Address" 
                                value={walletAddress}
                                onChange={handleTaxIdChange}
                            />
                            <img    
                                className="h-6 w-6 -ml-7"
                                src={getCurrencyIMG(state.receiveCurrency)}
                                alt={`${state.receiveCurrency} flag`}
                            />
                        </div>
                        <div>
                            {errorMessage && 
                                <TextModel addons="text-red-600 text-center" content={errorMessage} />}
                        </div>
                    </>
                )}

                {loading && <Loading text="Processing..." />}

                {completed && <Completed {...onSuccessMessage} />}
            </ConversionContainer>
        </ContainerService>
    );
};

export default ConversionStep2;
