import React from 'react';
import logo from './logo.svg';
import './App.css';
import AppRoutes from "./client/routes";
import { CurrencyProvider } from './context/Currency/CurrencyContext';

function App() {
  return (
    <div className="App">
       <CurrencyProvider>
      <AppRoutes />
      </CurrencyProvider>
    </div>
  );
}

export default App;
