import { ConvertOperation } from "../@types/Operations/ConvertOperation";
import ValueSelect from "../ValueSelect/ValueSelect";
import { useEffect, useState } from "react";
import { CurrencyActions, useCurrency } from "../../../context/Currency/CurrencyContext";

const InputSelect = ({ coin, state, value, dispatch,
     setInputValue, setOutputValue, topIcon, topText }: ConvertOperation) => {
   
    const [availableValue, setAvaliableValue] = useState(0);


    const handleMaxButtonClick = () => {

        const maxValue = availableValue;
        
       
        setInputValue(maxValue);
       

    
      
        
    }

    const handleCurrency = (currency: string) => {

        dispatch({
            type: CurrencyActions.setSendCurrency,
            payload: { sendCurrency: currency }
        });

    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {

        let inputValue = parseFloat(e.target.value);

        setInputValue(inputValue);
    
    
       
    
        
    };


    return (

        <ValueSelect

            index={1}
            coin={coin}
            inputValue={value}
            onChange={handleChange}
            onCurrencyChange={handleCurrency}
            topText={topText}
            topIcon={topIcon}
            handleMaxButtonClick={handleMaxButtonClick}
            isInput={true}

        />

    );
}

export default InputSelect;