import React from 'react';

const LogoMoonbeam: React.FC = () => {
    return (
        <svg
            id="Layer_2"
            data-name="Layer 2"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 3362.09 500"
            className='w-[200px]'
        >
            <defs>
                <style>
                    {`
                    .cls-1 {
                        fill: #2b1d3c;
                        stroke-width: 0px;
                    }
                    `}
                </style>
            </defs>
            <g id="Layer_1-2" data-name="Layer 1">
                <g>
                    <path className="cls-1" d="M456.65,377.04c.07-11.18,42.77-10.71,42.48-21.18-.21-8.05-19.11-7.87-13.17-24.94,8.74-25.46,13.5-52.77,13.5-81.18C499.46,111.81,387.65,0,249.73,0S0,111.81,0,249.73c0,28.51,4.79,55.89,13.6,81.42.05.12.54,1.57.54,1.57,3.55,11.32-14.09,15.76-14.09,22.52,0,10.59,37.15,9.16,37.15,21.49,0,10.73-36.73,14.04-36.73,24.87,0,14.96,81.35,9.75,82.38,22.62.8,9.93-27.97,10.52-28.13,21.14-.28,16.93,111.03,11.39,111.03,22.66,0,8.53-50.63,5.45-50.63,15.52s66.65,16.46,140.08,16.46,124.11-8.17,124.11-17-47.27-7-46.31-14.98c1.72-13.9,106.64-6.79,106.64-22.87,0-8.48-28.86-10.45-27.92-19.59,1.32-12.92,87.74-10.99,87.74-24.4,0-10.54-42.81-13.13-42.76-24.07l-.05-.05ZM389.7,344.61c-2.28,8.53,24.02,9.79,24.02,18.69,0,10.83-80.22,6.51-79.42,20.83.49,9.02,57,10.54,55.92,19.96-1.55,13.64-87.5,6.83-88.35,19.89-.61,9.58,41.94,9.91,42.04,18.65.07,7.35-33.44,11.37-97.6,11.37-73.11,0-108.64-3.95-104.6-12.16,3.78-7.68,68.5-6.25,67.85-18.32-.66-12.07-114.93-8.5-118.38-20.2-2.87-9.72,37.55-11.72,37.29-20.95-.26-9.42-51.76-7.28-52.89-18.67-.73-7.49,26.91-7,25.71-16.42-.87-6.86-12.28-5.89-19.05-20.01-10.92-23.6-17.05-49.86-17.05-77.54,0-101.9,82.59-184.49,184.49-184.49s184.49,82.59,184.49,184.49c0,25.13-5.03,49.08-14.14,70.92-8.67,19.63-28.18,15.69-30.39,23.95h.05Z" />
                    <path className="cls-1" d="M1013.72,83.27v332.53h-48.94V139.33l-98.82,276.48h-51.78l-97.39-274.11v274.11h-48.94V83.27h73.65l98.82,280.28,99.76-280.28h73.65Z" />
                    <path className="cls-1" d="M2077.45,83.27v332.53h-61.29l-158.66-269.83v269.83h-49.41V83.27h65.57l154.41,261.75V83.27h49.41-.03Z" />
                    <path className="cls-1" d="M2387.97,323.66c0,55.12-42.29,92.15-106.88,92.15h-145.37V83.27h139.2c62.22,0,104.03,35.16,104.03,86.94,0,33.25-16.62,59.39-45.6,73.15,34.22,13.29,54.63,41.8,54.63,80.29ZM2273.47,126.51h-86.93v98.33h86.93c33.26,0,55.09-19.96,55.09-48.94s-21.83-49.41-55.09-49.41v.02ZM2337.13,319.38c0-31.82-22.79-52.25-58.93-52.25h-91.66v105h92.14c36.11,0,58.42-21.37,58.42-52.72l.02-.02Z" />
                    <path className="cls-1" d="M2656.8,415.83h-225.65V83.27h223.75v44.64h-172.92v95.96h155.82v44.67h-155.82v102.6h174.82v44.69Z" />
                    <path className="cls-1" d="M2908.64,341.25h-152.5l-28.5,74.58h-53.21l131.57-332.53h53.69l131.11,332.53h-53.67l-28.52-74.58h.04ZM2892.98,299.42l-60.32-158.66-60.34,158.66h120.67Z" />
                    <path className="cls-1" d="M3362.09,83.27v332.53h-48.94V139.33l-98.83,276.48h-51.77l-97.4-274.11v274.11h-48.94V83.27h73.65l98.81,280.28,99.77-280.28h73.65Z" />
                    <path className="cls-1" d="M1227.7,121.39c34.05,0,66.04,13.27,90.11,37.34,24.07,24.07,37.34,56.08,37.34,90.11s-13.27,66.04-37.34,90.11c-24.07,24.07-56.08,37.34-90.11,37.34s-66.04-13.27-90.11-37.34c-24.07-24.07-37.34-56.08-37.34-90.11s13.27-66.04,37.34-90.11c24.07-24.07,56.08-37.34,90.11-37.34ZM1227.7,77.1c-94.85,0-171.74,76.89-171.74,171.74s76.89,171.74,171.74,171.74,171.74-76.89,171.74-171.74-76.89-171.74-171.74-171.74Z" />
                    <path className="cls-1" d="M1595.35,121.39c34.05,0,66.04,13.27,90.11,37.34,24.07,24.07,37.34,56.08,37.34,90.11s-13.27,66.04-37.34,90.11c-24.07,24.07-56.08,37.34-90.11,37.34s-66.04-13.27-90.11-37.34c-24.07-24.07-37.34-56.08-37.34-90.11s13.27-66.04,37.34-90.11c24.07-24.07,56.08-37.34,90.11-37.34ZM1595.35,77.1c-94.85,0-171.74,76.89-171.74,171.74s76.89,171.74,171.74,171.74,171.74-76.89,171.74-171.74-76.89-171.74-171.74-171.74Z" />
                </g>
            </g>
        </svg>
    );
}

export default LogoMoonbeam;
