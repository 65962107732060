import { useEffect, useState } from "react";
import DepositsContainer from "../Conversion/components/Container/DepositsContainer";
import QRCode from "qrcode.react";
import { useCurrency } from "../../../context/Currency/CurrencyContext";
import ContainerService from "../Conversion/components/Container/ContainerService";
import { useNavigate } from "react-router-dom";
import LogoGreen from "../../../components/LogoGreen";
import Completed from "../Conversion/components/Completed/Completed";
import { getPixToUsdHistoryController } from "../../../controller/GetPixToUsdHistory/GetPixToUsdHistory";
const Deposit: React.FC = () => {
    const { state } = useCurrency();
    const [timeLeft, setTimeLeft] = useState(1800); // 30 minutes in seconds
    const [tx, setTx] = useState('');
    const [isCompleted, setIsCompleted] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        if (!state.brCode) {
            navigate('/');
            return;
        }

        if (timeLeft <= 0) {
            navigate('/');
            return;
        }

        const timerId = setInterval(() => {
            setTimeLeft((prevTime) => prevTime - 1);
        }, 1000);

        return () => clearInterval(timerId);
    }, [timeLeft, navigate, state.brCode]);

    const formatTime = (seconds: number) => {
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${minutes}:${secs < 10 ? '0' : ''}${secs}`;
    };

    const qrCode = (
        <QRCode
            value={state.brCode}
            size={150}
            renderAs="svg"
            imageSettings={{
                src: "/favicon.png",
                x: undefined,
                y: undefined,
                height: 24,
                width: 24,
                excavate: true,
            }}
        />
    );

    useEffect(() => {
        console.log("state.pixToUsdId: ", state.pixToUsdId);

        const fetchData = async () => {
            if (state.pixToUsdId) {
                try {
                    const result = await getPixToUsdHistoryController(state.pixToUsdId);
                    const tx = result.data?.depositsLogs?.[0]?.pixToTokenOps?.[0]?.smartContractOps?.[0]?.tx;

    if (tx) {
        setTx(tx)
        setIsCompleted(true)
    } else {
      console.log("Transaction data is not available.");
    }
                } catch (error) {
                    console.error("Error fetching pixToUsdHistory:", error);
                }
            }
        };

        const intervalId = setInterval(() => {
            fetchData();
        }, 5000); // Fetch every 5 seconds

        return () => clearInterval(intervalId);
    }, [state.pixToUsdId]);

    return (
        <ContainerService path='/' linkText="Dashboard">
      { !isCompleted ?      
        <>
        <div className="flex justify-center items-center mb-8 mr-10">
                <LogoGreen />
            </div>
            <div className="text-center my-4">
                <h2 className="text-2xl font-bold">Time left: {formatTime(timeLeft)}</h2>
            </div>
            <DepositsContainer
                qrCode={qrCode}
                heading={"Scan the QR code or use the pix key"}
                subHeading="In a few seconds, your balance will be available."
                description="Check our terms and services policy here. Also, pay attention to our Compliance, AML, and CFT policy."
                copyItem={state.brCode}
                activeIndex={2}
            /> 
            </> : <Completed buttonText='Back' text={`track your transaction: `} link={`https://moonscan.io/tx/${tx}`} path='/' completeMessage='Transaction completed' image='/completed-icon.svg' />}
        </ContainerService>
    );
}

export default Deposit;
