import React from "react";
import CurrencyDropdown from "../Conversion/components/CurrencyDropdown/CurrencyDropdown";
import TextModel from "../../../components/Text/text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import InputMoney from "../Conversion/components/InputMoney/InputMoney";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { formatNumberToString } from "../../../components/services/formatNumber";
type ValueSelectConfig = {
    
    topIcon?: IconProp;
    topText?: string;
    toggleButtonPresent?: boolean;
    inputValue: number;
    coin: string;
    onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
    onCurrencyChange: (currency: string) => void;
    dataType?: string;
    index: number;
    handleMaxButtonClick?: () => void;
    isInput: boolean
 
}; 

const ValueSelect: React.FC<ValueSelectConfig> = ({
    topIcon,
    topText,
    inputValue,
    onChange,
    onCurrencyChange,
    coin,
    dataType,
    index,
    handleMaxButtonClick,
    isInput


}) => {

   
    let availableValue: number;

    switch (coin) {

        case 'BRL':
            break;
        case 'USDC':
            break;
        case 'USDT':
            break;
        default:
            availableValue = 0;

    }

    const formattedAvailableValue = formatNumberToString(100, coin);


    return (

        <div className={`text-start mt-3`} data-type={dataType}>

            <div className={`flex justify-between w-full items-center mb-3 gap-2`}>

                <div className={`flex items-center gap-2`}>
                    {topIcon && <FontAwesomeIcon icon={topIcon} />}
                    <TextModel color='text-black' content={topText} />
                </div>

            </div>

            <div className={`p-3 bg-gray-100 flex items-center justify-between
             rounded-lg flex-col md:flex-row`}>
                <div className={`flex gap-2 w-full flex-col`}>

                    <CurrencyDropdown index={index} coin={coin} onCurrencyChange={onCurrencyChange} isInput={isInput} />
                    
                    
                </div>

                <div className={`flex jutify-between e-full md:flex-col items-center md:items-end`}>
                    <InputMoney value={inputValue} onChange={onChange} />
                </div>
                
            </div>
        </div>

    );
};

export default ValueSelect;