import { http } from "../ConnectApi/ConnectApi";

export async function fastQuoteController(data: any) {
  const queryParams = new URLSearchParams(data).toString();

  try {
    const response = await http.get(`business/fast-quote?${queryParams}`, {
      withCredentials: true
    });

    
    return response;
  } catch (error: any) {
    console.error('Error during register:', error.response?.data || error.message);
    throw error;
  }
}
