import React, { useEffect, useState, useRef } from "react";
import ContainerService from "../Container/ContainerService";
import ConversionContainer from "../Container/ConversionContainer";
import IconBall from "../../../IconBall/IconBall";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown, faArrowRight } from "@fortawesome/free-solid-svg-icons";
import InputSelect from "../../../MoneySelect/InputSelect";
import OutputSelect from "../../../MoneySelect/OutputSelect";
import TextModel from "../../../../../components/Text/text";
import { fastQuoteController } from "../../../../../controller/FastQuoteController/FastQuoteController";
import { CurrencyActions, useCurrency } from "../../../../../context/Currency/CurrencyContext";
import { useNavigate } from "react-router-dom";

interface ConversionStep1Props {
    onClick: () => void;
}

const useInterval = (callback: () => void, delay: number | null) => {
    const savedCallback = useRef(callback);

    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        if (delay !== null) {
            const id = setInterval(() => savedCallback.current(), delay);
            return () => clearInterval(id);
        }
    }, [delay]);
};

const ConversionStep1: React.FC<ConversionStep1Props> = ({ onClick }) => {
    const { state, dispatch } = useCurrency();
    const [inputValue, setInputValue] = useState(50);
    const [outputValue, setOutputValue] = useState(0);
    const [errorMessage, setErrorMessage] = useState('');
    const [amountToken, setAmountToken] = useState('');
    const navigate = useNavigate();

    const debouncedFastQuote = useRef(debounce(async (data) => {
        try {
            const ApiResponse = await fastQuoteController(data);
            const response = ApiResponse.data;
            const amountToken = response.amountToken; 
            const amountBrl = response.amountBrl;
            
            dispatch({
                type: CurrencyActions.setReceiveValue,
                payload: { receiveValue: amountToken }
            });

            dispatch({
                type: CurrencyActions.setSendValue,
                payload: { sendValue: amountBrl }
            });

            if (parseFloat(amountToken) === 0) {
                setErrorMessage("The amount is too low; please increase it.");
            } else {
                setErrorMessage("");
            }

            setAmountToken(amountToken);
            return amountToken;
        } catch (error: any) {
            if (error.response?.status === 401) {
                navigate('/login');
            }
            console.error("Error in debouncedFastQuote:", error.response?.data || error.message);
            console.log(error.response);
            setErrorMessage(error.response?.data?.message || error.response?.data?.error || "An error occurred while fetching the quote.");
        }
    }, 500));

    const chains = {
        'BRL': '',
        'GLMR': 'Moonbeam',
        'MATIC': 'Polygon',
        'CHZ': 'Chiliz',
        'ETH': 'Ethereum'
    };

    const fetchQuote = () => {
        const inputString = (inputValue * 100).toFixed(0);
        const inputInt = parseFloat(inputString);
        const data = {
            operation: "pix-to-token",
            amount: inputInt,
            inputCoin: "BRLA",
            outputCoin: state.receiveCurrency,
            chain: chains[state.receiveCurrency],
        };
        debouncedFastQuote.current(data);
    };

    useEffect(() => {
        fetchQuote();
    }, [inputValue, state.receiveCurrency]);

    useInterval(() => {
        fetchQuote();
    }, 5000);

    function debounce<T extends (...args: any[]) => any>(func: T, delay: number): (...args: Parameters<T>) => Promise<ReturnType<T>> {
        let timeout: ReturnType<typeof setTimeout>;

        return function (this: ThisParameterType<T>, ...args: Parameters<T>): Promise<ReturnType<T>> {
            const context = this;

            return new Promise((resolve, reject) => {
                clearTimeout(timeout);

                timeout = setTimeout(async () => {
                    try {
                        const result = await func.apply(context, args);
                        resolve(result);
                    } catch (error) {
                        reject(error);
                    }
                }, delay);
            });
        };
    }

    function isValid() {
        return inputValue >= 10 && parseFloat(amountToken) > 0;
    }

    return (
        <ContainerService path='/' linkText="Dashboard">
            <ConversionContainer
                onClick={onClick}
                activeIndex={0}
                buttonComponent={<span>Next <FontAwesomeIcon icon={faArrowRight} /></span>}
                isDisabled={!isValid()}
            >
                <InputSelect
                    coin={'BRL'}
                    value={inputValue}
                    dispatch={dispatch}
                    state={null}
                    setInputValue={setInputValue}
                    setOutputValue={setOutputValue}
                />

                <IconBall absolute icon={faArrowDown} />

                <OutputSelect
                    coin={state.receiveCurrency}
                    value={parseFloat(amountToken)}
                    state={state}
                    dispatch={dispatch}
                    setInputValue={setInputValue}
                    setOutputValue={setOutputValue}
                />

                <div>
                    {inputValue < 10 && 
                        <TextModel addons="text-red-600 text-center" content="The amount must be at least 10." />}
                    {errorMessage && 
                        <TextModel addons="text-red-600 text-center" content={errorMessage} />}
                </div>

                <div className="mt-6">
                    <div className="flex justify-between">
                        <TextModel addons="text-gray-400" weight="font-light" content={"Exchange rate"} />
                        <TextModel addons="text-gray-400" weight="font-light" content={isNaN(state.sendValue / state.receiveValue) ? '' : `${(state.sendValue / state.receiveValue).toFixed(2)} BRL`} />
                    </div>
                </div>
            </ConversionContainer>
        </ContainerService>
    );
};

export default ConversionStep1;
