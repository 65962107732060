import React, { useState } from 'react';
import ConversionStep1 from "./Conversion/components/Step1/ConversionStep1";
import ConversionStep2 from './Conversion/components/Step2/ConversionStep2';
import LogoGreen from '../../components/LogoGreen';
import Completed from './Conversion/components/Completed/Completed';

export default function Home() {
  const [currentStep, setCurrentStep] = useState(1);

  function changeStep() {
    setCurrentStep(2);
  }

  return (
    <div className="flex flex-col justify-center items-center min-h-screen">
      <div className="flex justify-center items-center mb-8 mr-10">
        <LogoGreen />
      </div>
      {currentStep === 1 && <ConversionStep1 onClick={changeStep} />}
      {currentStep === 2 && <ConversionStep2 />}
      
    </div>
  );
}
