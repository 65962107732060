import LogoBrla from "./LogoBrla/LogoBrla";
import LogoMoonbeam from "./LogoMoonbeam/LogoMoonbeam";

export default function LogoGreen() {
  return (
    <div className="flex">
<LogoBrla/>
<LogoMoonbeam/>
    </div>  

  );
}
