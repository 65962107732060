

export function getCurrencyIMG(currency: string): string {

    switch (currency) {

        case 'BRL':
            return "https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/800px-Flag_of_Brazil.svg.png";
        case 'USDC':
            return "https://cdn.jsdelivr.net/gh/spothq/cryptocurrency-icons@master/32/color/usdc.png";
        case 'USDT':
            return "https://cdn.jsdelivr.net/gh/spothq/cryptocurrency-icons@master/32/color/usdt.png";
            case 'GLMR':
            return "https://assets.coingecko.com/coins/images/22459/standard/Moonbeam_GLMR_ICON.png?1716647586";
              case 'MATIC':
            return "https://assets.coingecko.com/coins/images/4713/standard/polygon.png?1698233745"
            case 'CHZ':
            return "https://assets.coingecko.com/coins/images/8834/standard/CHZ_Token_updated.png?1696508986"
            case 'ETH':
            return "https://assets.coingecko.com/coins/images/279/standard/ethereum.png?1696501628"
            case 'BRLA':
            return "https://brla.digital/favicon.png";
        default:
            return 'https://upload.wikimedia.org/wikipedia/commons/thumb/0/05/Flag_of_Brazil.svg/800px-Flag_of_Brazil.svg.png';

    }
    
}